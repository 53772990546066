<template>
  <v-form class="pa-5" ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="form.companyName"
      label="Company Name"
      prepend-icon="mdi-office-building"
      @input="companyNameCapitalLetter"
      required
    ></v-text-field>
    <v-select
      v-model="form.tradingTypeId"
      :items="tradingTypeList"
      item-text="value"
      item-value="id"
      label="Trading Type"
      prepend-icon="mdi-domain"
    ></v-select>
    <v-text-field
      v-if="showRegistrationNo"
      v-model="form.registrationNo"
      :rules="registrationNoRules"
      label="Registration Number"
      prepend-icon="mdi-pound"
    ></v-text-field>
    <v-autocomplete
      :items="natureOfBusinessList"
      color="white"
      v-model="form.natureOfBusinessId"
      item-text="value"
      item-value="id"
      label="Nature of Business"
      prepend-icon="mdi-tractor-variant"
      clearable
    ></v-autocomplete>
    <v-text-field
      v-model="form.website"
      label="Website"
      prepend-icon="mdi-at"
    ></v-text-field>
    <v-select
      v-model="form.primaryContact"
      :items="contactItems"
      item-text="value"
      item-value="id"
      label="Primary Contact"
      prepend-icon="mdi-account-box"
    ></v-select>
    <v-select
      v-model="form.primaryAddress"
      :items="addressItems"
      item-text="value"
      item-value="id"
      label="Primary Address"
      prepend-icon="mdi-office-building-marker"
    ></v-select>

    <div class="mt-4">
      <v-btn depressed color="accent" @click="submitForm" block dark large>
        Save changes
      </v-btn>
    </div>
    <!-- <div class="mt-4">
      <v-btn
        depressed
        color="accent"
        @click="updatePrimaryContact"
        block
        dark
        large
      >
        Update Primary Contact
      </v-btn>
    </div> -->
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import {
  mergeExistingEntriesIntoForm,
  changesSaved,
  somethingWentWrong,
} from "@/helpers/utilities"
// import { getCompanyState } from "@/store/helpers/default-state"
// import { required } from "@/helpers/form-validations"

export default {
  data: () => ({
    valid: true,
    form: {
      companyId: 0,
      tradingTypeId: "",
      natureOfBusinessId: "",
      primaryAddress: "",
      oldPrimaryAddress: "",
      primaryContact: "",
      oldPrimaryContact: "",
      website: "",
      registrationNo: "",
      companyName: "",
    },
  }),
  watch: {
    formEditMode(value) {
      if (!value) {
        this.$refs.form.reset()
      } else {
        this.bindCompanyDetails()
      }
    },
  },
  computed: {
    ...mapGetters("ui", ["formEditMode"]),
    ...mapGetters("company", ["details", "companyId"]),
    ...mapGetters("forms", ["natureOfBusinessList", "tradingTypeList"]),
    ...mapGetters("contacts", ["contactItems", "primaryContact", "contacts"]),
    ...mapGetters("addresses", ["addressItems", "primaryAddress"]),
    ...mapGetters("employee", ["userId"]),

    showRegistrationNo() {
      const tradinTypeValue = this.form.tradingTypeId
      return tradinTypeValue !== 6 && tradinTypeValue !== 8
    },
    registrationNoRules() {
      return [
        // value => !!value || 'Please Provide Correct Registration Number',
        (v) =>
          /[0-9]+$/.test(v) || "Please Provide Correct Registration Number",
      ]
    },
  },
  methods: {
    ...mapActions("ui", ["closeForm"]),
    ...mapActions("forms", ["fetchNatureOfBusiness", "fetchTradingTypes", "fetchMainData"]),
    ...mapActions("company", ["updateCompany", "fetchCompanyDetails"]),
    ...mapActions("contacts", ["updateContact"]),
    ...mapActions("contacts", ["changePrimaryContact", "fetchContacts"]),
    ...mapActions("addresses", ["changePrimaryAddress", "fetchAddresses"]),
    ...mapActions("notes", ["fetchNotes"]),
    submitForm() {
      if (this.formEditMode) {
        const registrationNo = this.form.registrationNo
          ? this.form.registrationNo
          : ""
        const noteSectionsEdited = []
        if(this.form.companyName !== this.details.companyName) {
          noteSectionsEdited.push({sectionEdited: "Company Name", oldVal: this.details.companyName, newVal: this.form.companyName})
        }
        if(this.form.tradingTypeId !== this.details.tradingTypeId) {
          const oldTradingType = this.tradingTypeList.filter(tradingType => tradingType.id === this.details.tradingTypeId)
          const newTradingType = this.tradingTypeList.filter(tradingType => tradingType.id === this.form.tradingTypeId)
          noteSectionsEdited.push({sectionEdited: "Trading Type", oldVal: oldTradingType[0].value, newVal: newTradingType[0].value})
        }
        if(this.form.registrationNo !== this.details.registrationNo) {
          noteSectionsEdited.push({sectionEdited: "Registration Number", oldVal: this.details.registrationNo, newVal: this.form.registrationNo})
        }
        if(this.form.natureOfBusinessId !== this.details.natureOfBusinessId) {
          const oldNatureOfBusiness = this.natureOfBusinessList.filter(natureOfBusiness => natureOfBusiness.id === this.details.natureOfBusinessId)
          const newNatureOfBusiness = this.natureOfBusinessList.filter(natureOfBusiness => natureOfBusiness.id === this.form.natureOfBusinessId)
          noteSectionsEdited.push({sectionEdited: "Nature of Business", oldVal: oldNatureOfBusiness[0].value, newVal: newNatureOfBusiness[0].value})
        }
        if(this.form.website !== this.details.website) {
          noteSectionsEdited.push({sectionEdited: "Website", oldVal: this.details.website, newVal: this.form.website})
        }
        if(this.form.primaryContact !== this.form.oldPrimaryContact) {
          const oldContactItem = this.contactItems.filter(contactItem => contactItem.id === this.form.oldPrimaryContact)
          const newContactItem = this.contactItems.filter(contactItem => contactItem.id === this.form.primaryContact)
          noteSectionsEdited.push({sectionEdited: "Primary Contact", oldVal: this.form.oldPrimaryContact !== "" ? oldContactItem[0].value : "Null", newVal: newContactItem[0].value})
        }
        if(this.form.primaryAddress !== this.form.oldPrimaryAddress) {
          const oldAddressItem = this.addressItems.filter(addressItem => addressItem.id === this.form.oldPrimaryAddress)
          const newAddressItem = this.addressItems.filter(addressItem => addressItem.id === this.form.primaryAddress)
          noteSectionsEdited.push({sectionEdited: "Primary Address", oldVal: this.form.oldPrimaryAddress !== "" ? oldAddressItem[0].value : "Null", newVal: newAddressItem[0].value})
        }
        let noteMessage = ""
        for (let i = 0; i < noteSectionsEdited.length; i++) {
          if(i === 0) {
            noteMessage = noteMessage + `Edit Company Section | ${noteSectionsEdited[i].sectionEdited} from ${noteSectionsEdited[i].oldVal} changed to ${noteSectionsEdited[i].newVal}<br>`
          }else {
            noteMessage = noteMessage + `Edit Company Section | ${noteSectionsEdited[i].sectionEdited} - ${noteSectionsEdited[i].oldVal} changed to ${noteSectionsEdited[i].newVal}<br>`
          }
          
        }

        const userId = this.userId
        this.updateCompany({
          // ...defaultCompany,
          ...this.form,
          registrationNo,
          noteMessage,
          userId,
        }),
        this.changePrimaryContact(this.form.primaryContact)
        this.changePrimaryAddress(this.form.primaryAddress)
        changesSaved("Company Information Successfully Edited")
        setTimeout(() => {
          this.fetchCompanyDetails(), this.fetchContacts()
          this.fetchMainData(this.companyId)
          this.fetchAddresses()
          this.fetchNotes()

          this.closeForm()
        }, 1000)
      } else {
        somethingWentWrong()
      }
    },
    // updatePrimaryContact(){
    //   const primaryContact = this.form.primaryContacId
    //   this.changePrimaryContact({
    //         primaryContact
    //       })
    // },
    updatePrimaryAddress() {
      const primaryAddress = this.form.primaryAddress
      this.updateAddress({
        primaryAddress,
      })
    },
    bindCompanyDetails() {
      if (this.formEditMode) {
        Object.entries(this.details).forEach(
          mergeExistingEntriesIntoForm(this.form)
        )
        // primary address and contact does not exist in the actual company schema
        // We update them individually
        this.form.primaryAddress = this.primaryAddress ?  this.primaryAddress.addressId : ''
        this.form.oldPrimaryAddress = this.primaryAddress ?  this.primaryAddress.addressId : ''
        this.form.primaryContact = this.primaryAddress ? this.primaryContact.contactId : ''
        this.form.oldPrimaryContact = this.primaryAddress ? this.primaryContact.contactId : ''

      }
    },
    companyNameCapitalLetter() {
      this.form.companyName = this.form.companyName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },
    validate() {
      this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
  },
  created() {
    this.bindCompanyDetails()
  },
  mounted() {
    this.fetchAddresses()
    this.fetchContacts()
    this.fetchCompanyDetails()
    this.fetchNatureOfBusiness()
    this.fetchTradingTypes()
  },
}
</script>
